import { useCallback, useMemo, useState } from 'react';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { useTranslations } from 'components/shared/i18n';
import { useGetRenterProfile } from 'services/renter/useGetRenterProfile';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectRenter } from 'redux/selectors/bookingEditor';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AddDriverDialog } from 'components/flexFlow/driver/addDriver/AddDriverDialog';
import { QuickDriverDialog } from 'components/flexFlow/driver/additionalDriver/QuickDriverDialog';
import {
  AdditionalDriverContainer,
  CardActionsContainer,
  DriverContent,
  DriverFlexFlowCard,
  DriverPage,
  DriverPageButton,
  DriverScrollContainer,
  PrimaryDriverContainer,
  StyledButtonGroup,
} from 'components/flexFlow/driver/Driver.styles';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import NetworkError from 'components/shared/errors/NetworkError';
import { Action } from 'components/shared/ui/card/CardTypes';
import { useGetAdditionalDriverProfiles } from 'services/renter/useGetAdditionalDriverProfiles';
import { DriverCard } from 'components/flexFlow/driver/DriverCard';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { useLocation, useNavigate } from 'react-router-dom';
import { HashPaths } from 'app/router/RouterPaths';
import { EMPTY_VALUE } from 'utils/constants';
import { EditDriverDialog } from 'components/flexFlow/driver/editDriver/EditDriverDialog';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';

export const Driver: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  const isReadOnlyFlow = useAppSelector(selectIsReadOnlyFlow);
  const primaryDriver = useAppSelector(selectRenter);
  const [editDriverOpen, setEditDriverOpen] = useState(false);
  const [additionalDriverData, setAdditionalDriverData] = useState<{ driver: DriverData | undefined; index: number }>({
    driver: undefined,
    index: -1,
  });
  const [quickAddDriverOpen, setQuickAddDriverOpen] = useState<boolean>(false);
  const { driverData, isRenterProfileLoading, isRenterProfileError, refetchDriverProfile } = useGetRenterProfile();
  const navigate = useNavigate();
  const [changePrimaryDriverDialogIsOpen, setChangePrimaryDriverDialogIsOpen] = useState(false);

  const { hash } = useLocation();
  const {
    data: additionalDriverProfiles,
    additionalDriversFetching,
    additionalDriversErrors,
    refetchAdditionalDriver,
  } = useGetAdditionalDriverProfiles();
  const { data: phoneTypeDomain, isFetching: isPhoneDomainLoading, isError: isPhoneDomainError } = usePhoneTypesQuery();
  const primaryDriverActions: Action[] = useMemo(() => {
    if (!(driverData && driverData?.lastName)) {
      return [
        {
          label: t('driverSearch.add'),
          testId: 'add-driver',
          onClick: () => navigate({ hash: HashPaths.AddDriver }, { replace: true }),
          color: 'primary',
        },
      ];
    } else if (!isReadOnlyFlow) {
      return [
        {
          label: t('common.edit'),
          onClick: () => setEditDriverOpen(true),
          testId: 'edit-driver',
          color: 'primary',
        },
      ];
    }

    return [];
  }, [driverData, isReadOnlyFlow, navigate, t]);

  const additionalDriverActions = useCallback(
    (driverData: DriverData, index: number): Action[] => {
      if (!isReadOnlyFlow) {
        return [
          {
            label: t('common.edit'),
            onClick: (): void => {
              setEditDriverOpen(true);
              setAdditionalDriverData({ driver: driverData, index });
            },
            testId: 'edit-additional-driver',
            color: 'primary',
          },
        ];
      }

      return [];
    },
    [isReadOnlyFlow, t]
  );

  const handleClick: () => void = () => {
    navigate({ hash: HashPaths.AddDriver }, { replace: true });
  };

  const handleToggle = (): void => {
    setQuickAddDriverOpen(true);
  };

  useEffectOnlyOnce(() => {
    // Show Search/Create Driver Modal if no driver present
    if (!primaryDriver) {
      navigate({ hash: HashPaths.AddDriver }, { replace: true });
    }
  });

  function getAddDriverDialogTitle(): string {
    if (hash === HashPaths.ReplaceDriver) return t('driver.changePrimaryDriver');
    return primaryDriver ? t('driver.additionalDrivers.title') : t('driver.addPrimaryDriver');
  }

  return (
    <DriverPage>
      <DriverFlexFlowCard data-testid={'driverFlexFlowCard'}>
        <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
        <DriverScrollContainer>
          <DriverContent>
            <PrimaryDriverContainer
              title={t('driver.primaryDriver')}
              data-testid={`primary-driver`}
              cardHeaderProps={{ headerActions: primaryDriverActions }}>
              {driverData && <DriverCard driver={driverData} phoneTypeDomain={phoneTypeDomain} />}
            </PrimaryDriverContainer>
            {additionalDriverProfiles?.map((driverData, index) => (
              <AdditionalDriverContainer
                key={`additional-driver-${index}`}
                data-testid={`additional-driver-${index}`}
                title={t('driver.additionalDrivers.additionalDrivers')}
                cardHeaderProps={{ headerActions: additionalDriverActions(driverData, index) }}>
                <DriverCard driver={driverData} phoneTypeDomain={phoneTypeDomain} isAdditionalDriver />
              </AdditionalDriverContainer>
            ))}
            {!isReadOnlyFlow && driverData && driverData?.lastName && additionalDriverProfiles?.length < 9 && (
              <CardActionsContainer>
                <StyledButtonGroup variant='contained' aria-label='split button'>
                  <DriverPageButton onClick={handleClick} primary data-testid='add-driver-button'>
                    <span>{t('driver.addDriverTitle')}</span>
                  </DriverPageButton>
                  <DriverPageButton
                    size='small'
                    aria-label='quick add driver'
                    aria-haspopup='menu'
                    onClick={handleToggle}
                    primary
                    data-testid='quick-add-driver-button'>
                    <ArrowDropDownIcon />
                  </DriverPageButton>
                </StyledButtonGroup>
              </CardActionsContainer>
            )}
          </DriverContent>
        </DriverScrollContainer>
        {(hash === HashPaths.AddDriver || hash === HashPaths.ReplaceDriver) && (
          <AddDriverDialog
            onClose={(): void => navigate({ hash: EMPTY_VALUE })}
            title={getAddDriverDialogTitle()}
            data-testid='add-driver-dialog'
            changePrimaryDriverDialogIsOpen={changePrimaryDriverDialogIsOpen}
            setChangePrimaryDriverDialogIsOpen={setChangePrimaryDriverDialogIsOpen}
          />
        )}
        {quickAddDriverOpen && (
          <QuickDriverDialog
            onClose={(): void => setQuickAddDriverOpen(false)}
            driverCount={additionalDriverProfiles.length}
            data-testid='quick-add-driver-dialog'
          />
        )}
        {editDriverOpen && (
          <EditDriverDialog
            driver={additionalDriverData.driver ?? driverData}
            onClose={(isUpdatingExistingDriver): void => {
              if (isUpdatingExistingDriver) {
                additionalDriverData.driver?.type
                  ? refetchAdditionalDriver(additionalDriverData.index, additionalDriverData.driver?.type)
                  : refetchDriverProfile();
              }
              setEditDriverOpen(false);
              setAdditionalDriverData({ driver: undefined, index: -1 });
            }}
            changePrimaryDriverDialogIsOpen={changePrimaryDriverDialogIsOpen}
            setChangePrimaryDriverDialogIsOpen={setChangePrimaryDriverDialogIsOpen}
          />
        )}
        <ProgressOverlay inProgress={isRenterProfileLoading || isPhoneDomainLoading || additionalDriversFetching} />
        {(isRenterProfileError || isPhoneDomainError || additionalDriversErrors) && <NetworkError />}
      </DriverFlexFlowCard>
    </DriverPage>
  );
};
