import { Box, Grid, Tooltip } from '@mui/material';
import { Body1, EhiButton, ehiTheme } from '@ehi/ui';
import { ConsentRadioGroup } from 'components/flexFlow/driver/consent/ConsentRadioGroup';
import { FormProvider, useForm } from 'react-hook-form';
import { FC, ReactElement, useMemo, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Section } from 'components/flexFlow/driver/driverForm/DriverForm.styles';
import { ConsentFields, ConsentProps, ConsentStatus } from 'components/flexFlow/driver/consent/ConsentFormTypes';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { GridItem } from 'components/shared/ui/styles/Grid.styles';
import { DOUBLE_DASH, EMPTY_VALUE } from 'utils/constants';
import { useReservationFlow } from 'hooks/useReservationFlow';
import InfoIcon from '@mui/icons-material/Info';
import {
  selectConsentCountry,
  selectConsentPhoneNumber,
  selectConsentStatus,
  selectRetrievedConsentData,
  selectConsentEdited,
} from 'redux/selectors/consent';
import { setSelectedStatus, setConsentEdited } from 'redux/slices/consent/consentSlice';
import { hasPreviousConsentData } from 'components/flexFlow/driver/consent/consentUtils';

export const ConsentSection: FC<ConsentProps> = ({ driverDetails }) => {
  const { t } = useTranslations();
  const readOnly = useAppSelector(selectIsReadOnlyFlow);
  const { isModifyFlow } = useReservationFlow();
  const consentData = useAppSelector(selectRetrievedConsentData);
  const country = useAppSelector(selectConsentCountry);
  const phoneNumber = useAppSelector(selectConsentPhoneNumber);
  const status = useAppSelector(selectConsentStatus);
  const isConsentEdited = useAppSelector(selectConsentEdited);
  const [modifyReadOnly, setModifyReadOnly] = useState(isModifyFlow);

  const formMethods = useForm({
    defaultValues: {
      [ConsentFields.Country]: country,
      [ConsentFields.PhoneNumber]: phoneNumber,
      [ConsentFields.Status]: status,
    },
  });

  const [open, setOpen] = useState<boolean>(false);
  const initialDispatch = useAppDispatch();
  const hasConsentData = useMemo(() => {
    return hasPreviousConsentData(consentData);
  }, [consentData]);

  const isReadOnlyConsentStatus = useMemo(() => {
    return hasConsentData && modifyReadOnly && !isConsentEdited;
  }, [hasConsentData, modifyReadOnly, isConsentEdited]);

  const displayReadOnlyConsentStatus = useMemo(() => {
    const consentStatus = consentData?.status ?? undefined;

    switch (consentStatus) {
      case ConsentStatus.Accepted:
        return (
          <>
            <Body1 data-testid='consent-status'>{t('consent.accepted')}</Body1>
            <Body1 data-testid='consent-phoneNumber'>{consentData ? consentData?.phoneNumber : DOUBLE_DASH}</Body1>
          </>
        );
      case ConsentStatus.Declined:
        return <Body1 data-testid='consent-status'>{t('consent.declined')}</Body1>;
      case ConsentStatus.Revoked:
        return <Body1 data-testid='consent-status'>{t('consent.revoked')}</Body1>;
      default:
        return <Body1 data-testid='consent-status'> {DOUBLE_DASH} </Body1>;
    }
  }, [consentData, t]);

  const displayConsentRadiGroup = (): boolean => {
    return !hasConsentData || !modifyReadOnly || isConsentEdited;
  };

  const disableEdit = useMemo(() => {
    const consentStatus = (consentData && consentData?.status) ?? undefined;
    return consentStatus !== ConsentStatus.Accepted || !modifyReadOnly || isConsentEdited;
  }, [consentData, modifyReadOnly, isConsentEdited]);

  const handleTooltipClose = (): void => {
    setOpen(false);
  };

  const handleTooltipOpen = (): void => {
    setOpen(true);
  };

  const handleClick = (): void => {
    setModifyReadOnly(false);
    initialDispatch(setConsentEdited(true));
    if (EMPTY_VALUE === status) {
      initialDispatch(setSelectedStatus(ConsentStatus.Accepted));
    }
  };

  const consentQuestion = (): ReactElement => {
    return (
      <Grid container item alignItems={'stretch'}>
        <Body1 data-testid={'consent-question'}>{t('consent.question')}</Body1>
        <div>
          <Tooltip
            onClose={handleTooltipClose}
            open={open}
            onOpen={(): void => setOpen(true)}
            disableInteractive
            title={t('consent.infoIconMessage')}
            placement='top'
            sx={{ color: ehiTheme.palette.primary.main, paddingLeft: ehiTheme.spacing(0.5) }}
            arrow
            slotProps={{
              popper: {
                disablePortal: true,
              },
            }}>
            <InfoIcon onClick={handleTooltipOpen} style={{ cursor: 'pointer' }} />
          </Tooltip>
        </div>
      </Grid>
    );
  };

  return (
    <>
      {readOnly ? (
        <Box>
          <Grid item xs={12}>
            <Caption2>{t('consent.consentStatus')}</Caption2>
            {displayReadOnlyConsentStatus}
          </Grid>
        </Box>
      ) : (
        <Box>
          <Section data-testid={'consentSection'}>
            <FormProvider {...formMethods}>
              <Box display={'flex'} flexDirection={'column'} paddingTop={ehiTheme.spacing(2)}>
                {consentQuestion()}
                <Caption2 marginTop={ehiTheme.spacing(1)} marginBottom={ehiTheme.spacing(-1)}>
                  {t('consent.consentStatus')}
                  {hasConsentData && isModifyFlow && (
                    <>
                      <EhiButton
                        data-testid='consentEditButton'
                        variant='text'
                        disabled={disableEdit}
                        onClick={handleClick}>
                        {t('common.edit')}
                      </EhiButton>
                    </>
                  )}
                  {isReadOnlyConsentStatus && displayReadOnlyConsentStatus}
                </Caption2>
                {displayConsentRadiGroup() && (
                  <Grid container item alignItems={'stretch'} spacing={2} marginTop={-2} marginLeft={-1}>
                    <GridItem sm>
                      <ConsentRadioGroup driverDetails={driverDetails} modifyReadOnly={modifyReadOnly} />
                    </GridItem>
                  </Grid>
                )}
              </Box>
            </FormProvider>
          </Section>
        </Box>
      )}
    </>
  );
};
