import { Box, Grid } from '@mui/material';
import { Body1, ehiTheme } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { StyledWarningIcon } from 'components/shared/ui/styles/WarningIcon.styles';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { mask } from 'utils/maskUtils';
import { parseUrn } from 'utils/urnUtils';
import { toDateTimeString } from 'utils/dateUtils';
import { LoyaltyProgramLogo } from 'components/shared/ui/logos/LoyaltyProgramLogo';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { FC, useMemo } from 'react';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { PhoneType } from 'services/renter/renterReferenceTypes';
import { getItemRates } from 'utils/peoUtils';
import { getCountry, getSubdivision } from 'utils/countryUtils';
import { useBranchInfoByUrnQuery, useCountriesQuery, useSubdivisionsQuery } from 'services/location/locationQueries';
import { ConsentSection } from 'components/flexFlow/driver/consent/ConsentSection';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectPickup } from 'redux/selectors/bookingEditor';
import { displayConsentSection } from 'components/flexFlow/driver/consent/consentUtils';
import { DriverVerification } from 'components/flexFlow/driver/driverVerification/DriverVerification';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { DOUBLE_DASH } from 'utils/constants';
import { formatName } from 'utils/renterUtils';
import { DriverGridItem } from 'components/flexFlow/driver/Driver.styles';
import { isVerificationEnabled } from 'utils/locationUtils';

type DriverCardProps = {
  driver: DriverData;
  phoneTypeDomain: PhoneType[] | undefined;
  isAdditionalDriver?: boolean;
};

export const DriverCard: FC<DriverCardProps> = ({ driver, phoneTypeDomain, isAdditionalDriver }) => {
  const { t } = useTranslations();
  const { locale } = useLocale();
  const readOnly = useAppSelector(selectIsReadOnlyFlow);
  const { data: countries } = useCountriesQuery();
  const { data: subDivisions } = useSubdivisionsQuery(parseUrn(driver?.license?.country) ?? '');
  const pickupData = useAppSelector(selectPickup);
  const { data: pickup } = useBranchInfoByUrnQuery(pickupData?.branch ?? '');
  const verificationEnabled = useMemo(() => {
    return isVerificationEnabled(pickup);
  }, [pickup]);

  const phoneType = useMemo(() => {
    return phoneTypeDomain?.find((phone) => phone.code === driver?.primaryPhone?.type)?.name;
  }, [driver?.primaryPhone?.type, phoneTypeDomain]);

  const containsDriverAddress = useMemo(() => {
    if (!driver.address) {
      return false;
    }

    let containsAddressInfo = false;
    for (const key in Object.keys(driver.address)) {
      if (Object.values(driver.address)[key].length > 0) {
        containsAddressInfo = true;
        break;
      }
    }

    return containsAddressInfo;
  }, [driver.address]);

  const showConsentSection = useMemo((): boolean => {
    return displayConsentSection(isAdditionalDriver, pickup);
  }, [isAdditionalDriver, pickup]);

  const driverLicenseItems = useMemo(() => {
    return [
      { testId: 'driver-license-number', value: mask(driver?.license?.number, 4) },
      {
        testId: 'driver-license-subdivision',
        value: getSubdivision(subDivisions, driver?.license?.countrySubdivision)?.name,
      },
      {
        testId: 'driver-license-country',
        value: getCountry(countries, parseUrn(driver?.license?.country))?.name,
      },
      {
        testId: 'driver-license-exp-date',
        value: `${t('driverSearch.dlExp')} ${
          toDateTimeString(driver?.license?.expirationDate, t('format.date')) ?? ''
        }`,
        addBreak: true,
        display: 'inline',
      },
      {
        testId: 'driver-dob-date',
        value: `${t('driverSearch.dob')} ${toDateTimeString(driver?.dob, t('format.date')) ?? ''}`,
        display: 'inline',
      },
    ];
  }, [countries, driver, subDivisions, t]);

  return (
    <Box display={'flex'} flexDirection={'column'} data-testid={'flexiFlow-driver-card'} gap={ehiTheme.spacing(2)}>
      <Grid container display={'flex'}>
        {driver?.lastName && (
          <DriverGridItem data-testid={'driver-contact-flex-flow'}>
            <Caption2 data-testid={'driver-contact-label'}>{t('driver.contact')}</Caption2>
            <Body1 bold data-testid={'driver-name'}>
              {/* Note: get driver profile doesn't return returnWarningFlag, so look for renterWarning object */}
              {driver?.renterWarning && (
                <StyledWarningIcon data-testid='renterWarningIcon' sx={{ paddingTop: ehiTheme.spacing(0.2) }} />
              )}
              {formatName(driver?.lastName, driver?.firstName) ?? DOUBLE_DASH}
            </Body1>
            <Body1 data-testid={'driver-profile-created'}>{`${t('driver.profileCreated')}: ${
              toDateTimeString(driver.creationDate, t('format.date')) ?? ''
            }`}</Body1>
            {phoneType && (
              <Body1 data-testid={'driver-phone-number'}>
                {`${phoneType}: ${formatPhoneNumber(driver?.primaryPhone?.number)}`}
              </Body1>
            )}
            <Body1 data-testid={'driver-email'} style={{ wordBreak: 'break-all' }}>{`${t('driver.email')}: ${
              driver?.email ?? ''
            }`}</Body1>
          </DriverGridItem>
        )}
        {containsDriverAddress && (
          <DriverGridItem item data-testid={'driver-address-flex-flow'}>
            <Caption2 data-testid={'driver-address-label'}>{t('driver.address')}</Caption2>
            {driver.address?.lines &&
              driver.address.lines.map((line: string, index: number) => {
                return (
                  <Body1 key={line} data-testid={`driver-address-line-${index + 1}`}>
                    {line}
                  </Body1>
                );
              })}
            <Body1 data-testid={'driver-address-info'}>
              {` ${
                !!driver.address?.city && driver.address?.city.length > 0
                  ? driver.address?.city + ','
                  : driver.address?.city
              } ${driver.address?.countrySubdivisionCode} ${driver.address?.postalCode} ${driver.address?.countryCode}`}
            </Body1>
          </DriverGridItem>
        )}
      </Grid>
      <Grid container display={'flex'}>
        {driver?.license && (
          <DriverGridItem data-testid={'driver-license-flex-flow'}>
            <Caption2 data-testid={'driver-license-label'}>{t('driver.license')}</Caption2>
            {driverLicenseItems.map((item, index) => {
              return item.addBreak ? (
                <div key={index}>
                  <Body1 display={item.display} data-testid={item.testId}>
                    {item.value}
                  </Body1>
                  <br />
                </div>
              ) : (
                <Body1 key={index} display={item.display} data-testid={item.testId}>
                  {item.value}
                </Body1>
              );
            })}
          </DriverGridItem>
        )}
        {driver?.loyaltyType && (
          <DriverGridItem data-testid={'driver-loyalty-flex-flow'}>
            <Caption2 data-testid={'driver-loyalty-label'}>{t('driver.loyalty')}</Caption2>
            <Body1 data-testid={'driver-loyalty-type'}>{driver.loyaltyProgram?.name}</Body1>
            <Body1 data-testid={'driver-loyalty-logo'} height={24}>
              <LoyaltyProgramLogo membershipProgram={driver.loyaltyType} height={24} width={64} />
            </Body1>
            <Body1 data-testid={'driver-loyalty-number'}>{driver.loyaltyNumber}</Body1>
          </DriverGridItem>
        )}
      </Grid>
      {isAdditionalDriver && (
        <Grid item xs={12}>
          <Caption2 data-testid={'driver-fees-label'}>{t('driver.additionalDrivers.driverFees')}</Caption2>
          <Body1 data-testid='driver-fees'>
            {driver.driverFees ? getItemRates(driver.driverFees, t, locale) : '--'}
          </Body1>
        </Grid>
      )}
      {(verificationEnabled || showConsentSection) && <EhiDivider light theme={ehiTheme} />}
      {verificationEnabled && <DriverVerification driverDetails={driver} readOnly={readOnly} />}
      {showConsentSection && <ConsentSection driverDetails={driver} modifyReadOnly={false} />}
    </Box>
  );
};
