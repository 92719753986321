import { createContext, Dispatch, FC, JSX, ReactNode, SetStateAction, useCallback, useContext, useState } from 'react';
import { ResActionButton } from 'context/resActions/ResActionButton';
import { useSaveActionContext } from 'context/saveAction/SaveActionContext';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import { saveToReservation } from 'services/booking/bookingService';
import {
  clearPersistedReservationData,
  getLocationHeaderFromUrl,
} from 'components/shared/preprocessor/ReservationSessionHelper';
import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectBookingEditorIssues,
  selectIsReadOnlyFlow,
  selectReservationData,
} from 'redux/selectors/bookingEditor';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import NetworkError from 'components/shared/errors/NetworkError';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { RouterPaths } from 'app/router/RouterPaths';
import { To, useNavigate } from 'react-router-dom';
import { useResSnackbarContext } from 'context/resSnackbar/ResSnackbarContext';
import { safelyCatchError } from 'utils/errorUtils';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useTranslations } from 'components/shared/i18n';
import { parseUrn } from 'utils/urnUtils';
import { generateSearchParams } from 'utils/routing/urlUtils';
import { InternalTransactionParams } from 'utils/routing/InternalTransactionParams';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { useStartReservationSession } from 'components/shared/preprocessor/useStartReservationSession';
import {
  AdditionalInfoReqBookingIssue,
  CAN_OVERRIDE_ERROR_CODES,
  INCOMPLETE_RES_OVERRIDE_ERROR_CODES,
} from 'utils/bookingUtils';
import {
  DEFAULT_ERROR_TITLE,
  ERROR_ALERT_VARIANT,
  INFO_ALERT_VARIANT,
  SelectedAction,
} from 'components/shared/alert/AlertDialogTypes';
import { useNotes } from 'components/notes/useNotes';
import { useRetrieveAdditionalInformation } from 'components/flexFlow/rateAndBilling/additionalInformation/useRetrieveAdditionalInformation';
import { useReservationFlow } from 'hooks/useReservationFlow';
import { useBookingIssuesHelper } from 'context/resActions/useBookingIssuesHelper';
import { createConsentCommunication, revokeConsentCommunication } from 'services/consent/consentCommunicationService';
import { ConsentSms } from 'services/consent/consentCommunicationTypes';
import { ConsentStatus } from 'components/flexFlow/driver/consent/ConsentFormTypes';
import parsePhoneNumber from 'libphonenumber-js';
import { alpha3ToAlpha2 } from 'i18n-iso-countries';
import { CountryCode } from 'libphonenumber-js/types';
import {
  selectConsentCountry,
  selectConsentPhoneNumber,
  selectConsentStatus,
  selectConsentUid,
  selectRetrievedConsentData,
  selectUpdateCheckBox,
} from 'redux/selectors/consent';
import { ISO2_COUNTRY_CODE_US } from 'utils/constants';
import { createOrUpdateConsent } from 'components/flexFlow/driver/consent/consentUtils';
import { logError } from 'components/shared/logger/splunkLogger';
import { ErrorSeverity } from '@ehi/analytics';

export type ResActionsContextType = {
  setUpdateFloatingButtonAction: Dispatch<SetStateAction<boolean>>;
};

export const ResActionsContext = createContext<ResActionsContextType>({
  setUpdateFloatingButtonAction: () => undefined,
});

export const useResActionsContext = (): ResActionsContextType => {
  const context = useContext(ResActionsContext);

  if (!context) {
    throw Error('ResActionsContext is not initialized');
  }

  return context;
};

type ResActionsProviderProps = {
  label: string;
  icon: JSX.Element;
  children?: ReactNode;
};

export const ResActionsProvider: FC<ResActionsProviderProps> = ({ label, icon, children }) => {
  const { t } = useTranslations();
  const { saveOnUpdate } = useSaveActionContext();
  const { clearEditorSession } = useReservationSessionHelper();
  const { startModifyEditorSession } = useStartReservationSession();
  const { showAlert } = useAlert();
  const { setSnackBarRes } = useResSnackbarContext();
  const navigate = useNavigate();
  const { isNotesPage } = useNotes();

  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const bookingEditorIssues = useAppSelector(selectBookingEditorIssues);
  const reservationData = useAppSelector(selectReservationData);
  const isViewFlow = useAppSelector(selectIsReadOnlyFlow);
  const { isModifyFlow } = useReservationFlow();
  const [updateFloatingButtonAction, setUpdateFloatingButtonAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const { additionalInfo: accountAdditionalInformation } = useRetrieveAdditionalInformation();
  const { getIssuesPath } = useBookingIssuesHelper();

  const selectedConsentCountry = useAppSelector(selectConsentCountry);
  const selectedConsentPhoneNumber = useAppSelector(selectConsentPhoneNumber);
  const selectedConsentStatus = useAppSelector(selectConsentStatus);
  const selectedConsentUid = useAppSelector(selectConsentUid);
  const retrievedConsentData = useAppSelector(selectRetrievedConsentData);
  const isUpdatePhoneCheckBoxSelected = useAppSelector(selectUpdateCheckBox);

  const handleResActionButtonClick = async (): Promise<void> => {
    if (updateFloatingButtonAction) {
      saveOnUpdate();
    } else if (isViewFlow) {
      // clear session data saved in startViewOnlySession before navigating to modify flow
      clearPersistedReservationData();
      await handleNavigatingToModifyFlow();
    } else {
      await saveEditorToReservation();
    }
  };

  const handleNavigatingToModifyFlow = useCallback(async () => {
    const resNumber = parseUrn(reservationData?.reservation);
    setLoading(true);
    const { errors } = await startModifyEditorSession(resNumber);
    if (!errors?.length) {
      navigate(
        {
          pathname: `/res/${resNumber}/modify/${RouterPaths.WhenAndWhere}`,
        },
        {
          replace: true,
        }
      );
      setLoading(false);
    } else {
      await showAlert({
        variant: 'error',
        description: `${t('error.resEditError')}: ${errors[0].localizedMessage}`,
      });
      setLoading(false);
    }
  }, [navigate, reservationData?.reservation, showAlert, startModifyEditorSession, t]);

  const consentCommunicationCall = useCallback(
    async (
      resNum: string,
      consentStatus: string,
      selectedCountry: string,
      phoneNumber: string,
      consentUid: string
    ): Promise<ConsentSms | undefined> => {
      const iso2CountryCode = selectedCountry ? alpha3ToAlpha2(selectedCountry) : ISO2_COUNTRY_CODE_US;
      switch (consentStatus) {
        case ConsentStatus.Accepted:
          return createConsentCommunication(
            resNum,
            false,
            parsePhoneNumber(phoneNumber, iso2CountryCode as CountryCode)?.number
          );
        case ConsentStatus.Declined:
          return createConsentCommunication(resNum, true);
        case ConsentStatus.Revoked:
          return revokeConsentCommunication(consentUid);
        default:
          return undefined;
      }
    },
    []
  );

  const createOrUpdateConsentCommunication = useCallback(async (): Promise<boolean | undefined> => {
    return createOrUpdateConsent(selectedConsentStatus, retrievedConsentData, isUpdatePhoneCheckBoxSelected);
    // need to add Tour account verification as well.
  }, [selectedConsentStatus, retrievedConsentData, isUpdatePhoneCheckBoxSelected]);

  const saveEditorToReservation = useCallback(
    async (skipValidation = false) => {
      if (bookingEditorIssues && bookingEditorIssues?.length > 0 && !skipValidation) {
        const accountAdditionalFiltered =
          accountAdditionalInformation
            ?.flatMap((account) => account.additionalInfoFields || [])
            .filter((item) => item.isRequiredAtReservation && !item.fieldValue) ?? [];

        const errorIssues = bookingEditorIssues.filter(
          (issue) => !CAN_OVERRIDE_ERROR_CODES.has(issue.code ?? '') && issue.code !== AdditionalInfoReqBookingIssue
        );
        const warningIssues = bookingEditorIssues.filter((issue) => CAN_OVERRIDE_ERROR_CODES.has(issue.code ?? ''));

        const issuesToShow = errorIssues.length > 0 ? errorIssues : warningIssues;
        const isError = errorIssues.length > 0;

        const getIssueDescriptions = (): { message: string; routePath: To | undefined }[] => {
          const descriptions = issuesToShow.map((issue) => ({
            message: issue.localizedMessage ?? issue.code ?? '',
            routePath: getIssuesPath(issue.code ?? ''),
          }));

          accountAdditionalFiltered.forEach((field) => {
            descriptions.push({
              message: `${field.fieldName} ${t('validation.isRequired')}`,
              routePath: getIssuesPath(AdditionalInfoReqBookingIssue),
            });
          });

          return descriptions;
        };

        const descriptions = getIssueDescriptions();
        const selectedAction = await showAlert({
          variant: isError ? ERROR_ALERT_VARIANT : INFO_ALERT_VARIANT,
          title: isError ? t(DEFAULT_ERROR_TITLE) : t('common.saveReservation'),
          subtitle: !isError && warningIssues.length > 0 ? t('common.confirmToProceedWithoutTheFollowing') : undefined,
          descriptions,
          primaryActionText: !isError ? t('common.confirm') : t('common.close'),
          secondaryActionText: !isError ? t('common.cancel') : undefined,
        });

        if (selectedAction === SelectedAction.Primary && !isError) {
          await saveEditorToReservation(true);
        }
      } else {
        try {
          const resSnackbarMessage = isModifyFlow ? 'quickReservation.resUpdated' : 'quickReservation.resCreated';
          setLoading(true);
          const { headers } = await saveToReservation(bookingEditorId, {
            overrideIssue: INCOMPLETE_RES_OVERRIDE_ERROR_CODES,
          });
          const resNum = getLocationHeaderFromUrl(headers?.location);

          try {
            if (await createOrUpdateConsentCommunication()) {
              await consentCommunicationCall(
                resNum,
                selectedConsentStatus,
                selectedConsentCountry,
                selectedConsentPhoneNumber,
                selectedConsentUid
              );
            }
          } catch (error) {
            logError({
              error: {
                message: 'Error for the consent communication API call',
                supportInformation: {
                  resNum,
                  serviceError: error,
                },
              },
              severity: ErrorSeverity.Warning,
            });
          }

          setSnackBarRes({
            onViewAction: () => {
              navigate(
                `${RouterPaths.PreProcessor}?${generateSearchParams({
                  [InternalTransactionParams.TransactionType]: TransactionTypes.View,
                  [InternalTransactionParams.Res]: resNum,
                })}`
              );
            },
            message: t(resSnackbarMessage, { resNum }),
            isOpen: true,
          });
          clearEditorSession();
          navigate(RouterPaths.Search);
        } catch (error) {
          const ehiErrorsResponse = safelyCatchError(error);
          await showAlert({ responseMessages: ehiErrorsResponse?.errors });
        } finally {
          setLoading(false);
        }
      }
    },
    [
      accountAdditionalInformation,
      bookingEditorId,
      bookingEditorIssues,
      clearEditorSession,
      getIssuesPath,
      isModifyFlow,
      navigate,
      setSnackBarRes,
      showAlert,
      t,
      selectedConsentStatus,
      selectedConsentCountry,
      selectedConsentPhoneNumber,
      selectedConsentUid,
      consentCommunicationCall,
      createOrUpdateConsentCommunication,
    ]
  );

  return (
    <ResActionsContext.Provider value={{ setUpdateFloatingButtonAction }}>
      <LoadableView
        loadingComponent={<FullScreenSpinner />}
        errorComponent={<NetworkError />}
        state={mapLoadingState(loading, false) ?? LoadingState.SUCCESS}>
        {children}
      </LoadableView>
      {!isNotesPage && (
        <ResActionButton
          label={label}
          icon={icon}
          updateFloatingButtonAction={updateFloatingButtonAction}
          handleButtonClick={handleResActionButtonClick}
        />
      )}
    </ResActionsContext.Provider>
  );
};
