import { useAppSelector } from 'redux/hooks';
import {
  selectBillingAccountAdditionalInformation,
  selectBillingAccountPayer,
  selectBookingEditorId,
  selectPickup,
  selectRateSource,
} from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useCallback } from 'react';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import {
  BusinessPayer,
  BusinessPayerType,
  NegotiatedRateSource,
  RateSource,
  RateSourceType,
} from 'services/booking/bookingTypes';
import { modifyRateSource, updateBusinessPayers } from 'services/booking/bookingService';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import {
  PeoBookingIssues,
  RateProductIsModifiedBookingIssue,
  VehicleAvailabilityBookingIssues,
} from 'utils/bookingUtils';
import { parseBillingAccountNumber } from 'utils/rateAndBillingUtils';
import { useAdditionalInformationQuery } from 'services/businessAccount/accountQueries';
import {
  isBillingSameAsRateSourceAndHasAdditionalInfo,
  mapEditorAdditionalInfo,
} from 'components/flexFlow/rateAndBilling/additionalInformation/additionalInformationUtils';
import { useBranchInfoByUrnQuery } from 'services/location/locationQueries';
import { useReservationFlow } from 'hooks/useReservationFlow';
import { useCarrierSafetyNumberUtility } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/carrierSafetyNumber/useCarrierSafetyNumberUtility';
import { isTruckLocation } from 'utils/locationUtils';
import { useTranslations } from 'components/shared/i18n';
import { parseUrn } from 'utils/urnUtils';

export type AddOrModifyRateSourceProps = {
  accountNumber: string;
  ratePlanId?: string;
  handleCloseModal: () => void;
  carrierSafetyNumbers: string[];
};

export type useRateAndBillingHookReturn = {
  addOrModifyRateSource: (props: AddOrModifyRateSourceProps) => Promise<void>;
};

export const useRateSource = (): useRateAndBillingHookReturn => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const rateSource = useAppSelector(selectRateSource);
  const { isModifyFlow } = useReservationFlow();
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { handleBookingIssues } = useBookingIssue();
  const billingAdditionalInformation = useAppSelector(selectBillingAccountAdditionalInformation);
  const billingAccountPayer = useAppSelector(selectBillingAccountPayer);
  const billingAccountNumber = parseBillingAccountNumber(billingAccountPayer);
  const { data: billingAccountAdditionalInfo } = useAdditionalInformationQuery(billingAccountNumber ?? '');
  const pickupData = useAppSelector(selectPickup);
  const { data: pickupBranch } = useBranchInfoByUrnQuery(pickupData?.branch ?? '');
  const { updateDefaultCarrierSafetyIdentifier } = useCarrierSafetyNumberUtility();
  const negotiatedRateSource = rateSource as NegotiatedRateSource;

  const addOrModifyRateSource = useCallback(
    async ({
      accountNumber,
      handleCloseModal,
      ratePlanId,
      carrierSafetyNumbers,
    }: AddOrModifyRateSourceProps): Promise<void> => {
      const accountIsSameAsEditorAccount: boolean = parseUrn(accountNumber) === parseUrn(negotiatedRateSource?.account);
      const responseMessages = [];

      let rateSourceAdditionalInformation = undefined;
      let billToAdditionalInformation = undefined;
      if (
        isBillingSameAsRateSourceAndHasAdditionalInfo(accountNumber, billingAccountNumber, billingAdditionalInformation)
      ) {
        const mappedAdditionalData = mapEditorAdditionalInfo(
          billingAccountAdditionalInfo,
          billingAdditionalInformation
        );
        rateSourceAdditionalInformation = mappedAdditionalData?.filter((item) => !item.onlyForBusinessPayer);
        billToAdditionalInformation = mappedAdditionalData?.filter((item) => item.onlyForBusinessPayer);
      }

      const rateSourceRequestBody: RateSource = {
        type: RateSourceType.NEGOTIATED,
        account: accountNumber,
        ratePlan: ratePlanId ?? undefined,
        additionalInformation:
          (accountIsSameAsEditorAccount &&
            negotiatedRateSource?.additionalInformation &&
            negotiatedRateSource?.additionalInformation?.length > 0 &&
            negotiatedRateSource?.additionalInformation) ||
          (rateSourceAdditionalInformation && rateSourceAdditionalInformation?.length > 0
            ? rateSourceAdditionalInformation
            : undefined),
      };
      const { data, errors } = await updateAndRefresh(async () => {
        await modifyRateSource(bookingEditorId, rateSourceRequestBody);
        if (isTruckLocation(pickupBranch?.type) && pickupData !== undefined && rateSource !== undefined) {
          await updateDefaultCarrierSafetyIdentifier(pickupBranch?.type, carrierSafetyNumbers);
        }
      });
      if (errors) {
        responseMessages.push(...errors);
      }
      if (billToAdditionalInformation && billToAdditionalInformation?.length > 0) {
        const requestBody: BusinessPayer[] = [
          {
            type: BusinessPayerType.BILLING_ACCOUNT,
            billingAccount: billingAccountPayer,
            additionalInformation: billToAdditionalInformation,
          } as BusinessPayer,
        ];

        const { errors: billingErrors } = await updateAndRefresh(() =>
          updateBusinessPayers(bookingEditorId, requestBody)
        );
        if (billingErrors) {
          responseMessages.push(...billingErrors);
        }
      }
      if (responseMessages.length === 0) {
        handleCloseModal();
        if (data) {
          const availableBookingIssues = [
            ...Object.values(VehicleAvailabilityBookingIssues),
            ...Object.values(PeoBookingIssues),
          ];
          const excludedBookingIssues = isModifyFlow ? [RateProductIsModifiedBookingIssue] : [];
          await handleBookingIssues(
            data.issue ?? [],
            data.ehiMessages ?? [],
            availableBookingIssues,
            t('snackbarMessages.additionalInfo.account'),
            excludedBookingIssues
          );
        }
      } else {
        await showAlert({
          variant: 'error',
          description: responseMessages.map((message) => message.localizedMessage || '').toString(),
        });
      }
    },
    [
      billingAccountNumber,
      billingAdditionalInformation,
      updateAndRefresh,
      billingAccountAdditionalInfo,
      bookingEditorId,
      pickupBranch?.type,
      pickupData,
      rateSource,
      updateDefaultCarrierSafetyIdentifier,
      billingAccountPayer,
      isModifyFlow,
      handleBookingIssues,
      t,
      showAlert,
      negotiatedRateSource?.account,
      negotiatedRateSource?.additionalInformation,
    ]
  );

  return {
    addOrModifyRateSource,
  };
};
