import { EMPTY_VALUE } from 'utils/constants';
import { ConsentFields, ConsentFieldValues, ConsentStatus } from 'components/flexFlow/driver/consent/ConsentFormTypes';
import { isCorporateCountry } from 'utils/locationUtils';
import { AddressType, BranchV2 } from 'services/location/locationTypes';
import { ConsentSms } from 'services/consent/consentCommunicationTypes';

export const consentInitialValues: ConsentFieldValues = {
  [ConsentFields.Country]: EMPTY_VALUE,
  [ConsentFields.PhoneNumber]: EMPTY_VALUE,
  [ConsentFields.Status]: EMPTY_VALUE,
};

export const displayConsentSection = (
  isAdditionalDriver: boolean | undefined,
  pickup: BranchV2 | undefined
): boolean => {
  return !isAdditionalDriver && isCorporateCountry(pickup?.addresses ?? [], AddressType.PHYSICAL);
};

export const hasPreviousConsentData = (consentData: ConsentSms | undefined): boolean => {
  return !!consentData?.status;
};

export const createOrUpdateConsent = (
  selectedConsentStatus: string,
  retrievedConsentData: ConsentSms | undefined,
  isUpdatePhoneCheckBoxSelected: boolean
): boolean => {
  return (
    selectedConsentStatus !== undefined &&
    selectedConsentStatus !== EMPTY_VALUE &&
    (retrievedConsentData?.status !== selectedConsentStatus ||
      (selectedConsentStatus === ConsentStatus.Accepted && isUpdatePhoneCheckBoxSelected))
  );
};
