import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { AddNotesFields, AddNotesValues } from 'components/notes/addNote/AddNotesTypes';
import { EMPTY_VALUE } from 'utils/constants';
import { AnyObject, ObjectSchema } from 'yup';

export const addNotesSchema = (
  t: TFunction<'translation'>
): ObjectSchema<{ notes: string | undefined }, AnyObject, { notes: undefined }, ''> =>
  Yup.object().shape({
    notes: Yup.string().containsCreditCardPattern(
      t('validation.creditCardNotAllowed', { textFieldLabel: t('notes.addNote') })
    ),
  });

export const addNotesInitialValues = (): AddNotesValues => ({
  [AddNotesFields.Notes]: EMPTY_VALUE,
});
