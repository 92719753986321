import { styled } from '@mui/material';
import { ehiTheme, H6 } from '@ehi/ui';
import Grid from '@mui/material/Grid';

export const SearchContainer = styled('div')(() => ({
  backgroundColor: ehiTheme.palette.background.default,
  borderBottom: `1px solid ${ehiTheme.ehi.palette.border.main}`,
  padding: ehiTheme.spacing(3),
}));

export const SearchButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: 'center';
`;

export const FlexContainer = styled('div')<{ $justifyContent?: string }>`
  display: flex;
  justify-content: ${(props): string => props.$justifyContent || 'flex-start'};
  flex-direction: column;
`;

export const DriverListItem = styled('div')(() => ({
  display: 'block !important',
}));

const gridItemProps = {
  item: true,
  xs: 12,
  sm: 4,
};

export const StyledGridItem = styled(Grid)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(0),
  },
}));
StyledGridItem.defaultProps = gridItemProps;

export const DriverSearchName = styled(H6)`
  white-space: nowrap;
  text-align: start;
  font-size: 16px;
`;
