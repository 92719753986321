import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isDevCypressRun } from 'utils/buildInfoUtil';
import { ConsentRetrieve, ConsentState } from 'redux/slices/consent/consentSliceTypes';

const win = window as any;
const initialState = (isDevCypressRun() && win?.initialState?.bookingEditor) || ({} as ConsentState);

const consentSlice = createSlice({
  name: 'consent',
  initialState: initialState as ConsentState,
  reducers: {
    setSelectedCountry: (state: ConsentState, action: PayloadAction<string>) => {
      state.selectedCountry = action.payload;
    },
    setPhoneNumber: (state: ConsentState, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setSelectedStatus: (state: ConsentState, action: PayloadAction<string>) => {
      state.selectedStatus = action.payload;
    },
    setConsentUid: (state: ConsentState, action: PayloadAction<string>) => {
      state.consentUid = action.payload;
    },
    setRetrievedConsentData: (state: ConsentState, action: PayloadAction<ConsentRetrieve>) => {
      state.consentData = action.payload.consent;
    },
    setUpdatePhoneCheckbox: (state: ConsentState, action: PayloadAction<boolean>) => {
      state.consentCheckBox = action.payload;
    },
    setConsentEdited: (state: ConsentState, action: PayloadAction<boolean>) => {
      state.consentEdited = action.payload;
    },
    resetConsentState: (state: ConsentState) => {
      state.selectedCountry = undefined;
      state.phoneNumber = undefined;
      state.selectedStatus = undefined;
      state.consentUid = undefined;
      state.consentCheckBox = false;
      state.consentEdited = false;
      state.consentData = undefined;
    },
  },
});

export const {
  setSelectedCountry,
  setPhoneNumber,
  setSelectedStatus,
  setConsentUid,
  setRetrievedConsentData,
  setUpdatePhoneCheckbox,
  setConsentEdited,
  resetConsentState,
} = consentSlice.actions;

export default consentSlice.reducer;
