import { EditorIssue, Equipment, Protection } from 'services/booking/bookingTypes';
import { EMPTY_VALUE } from 'utils/constants';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { DescriptionWithAction } from 'components/shared/alert/AlertDialogTypes';
import { uniq } from 'lodash';
import { parseUrn } from 'utils/urnUtils';

export enum VehicleAvailabilityBookingIssues {
  ReservedVehicleNotAvailable = 'BOOK2078',
  PreferredVehicleNotAvailable = 'BOOK2053',
}

export enum BillToBookingIssues {
  BillToInvalidLocation = 'BOOK2082',
  BillToAccountInactive = 'BOOK2083',
}

export enum PeoBookingIssues {
  EquipmentUnavailable = 'BOOK2039',
  ProtectionUnavailable = 'BOOK2034',
}

export const SupportingServiceBookingIssue = 'BOOK1000';
export const ProtectionPriceMismatchBookingIssue = 'BOOK2026';
export const InvalidRateProductBookingIssue = 'BOOK2070';
export const VehicleNotValidForYoungDriverBookingIssue = 'BOOK2072';
export const RateProductIsModifiedBookingIssue = 'BOOK2077';
export const DriverNotValidForBrandBookingIssue = 'BOOK2080';

export const DRIVER_ISSUE_CODES = ['BOOK2063', 'BOOK2057', 'BOOK2051', 'BOOK2080'];
export const WHEN_AND_WHERE_ISSUE_CODES = [
  'BOOK2044',
  'BOOK2045',
  'BOOK2046',
  'BOOK2047',
  'BOOK2048',
  'BOOK2049',
  'BOOK2050',
  'BOOK2075',
];
export const AdditionalInfoReqBookingIssue = 'BOOK2054';

export const VEHICLE_ISSUE_CODES = ['BOOK2052', 'BOOK2053', 'BOOK2072', 'BOOK2078'];
export const RATE_AND_BILL_ISSUE_CODES = ['BOOK2082', 'BOOK2083'];
export const ADDITIONAL_INFO_ISSUE_CODES = [AdditionalInfoReqBookingIssue];

export const INCOMPLETE_RES_OVERRIDE_ERROR_CODES = [
  'BOOK2049',
  'BOOK2047',
  'BOOK2050',
  'BOOK2052',
  'BOOK2078',
  'BOOK2053',
];
export const CAN_OVERRIDE_ERROR_CODES = new Set(INCOMPLETE_RES_OVERRIDE_ERROR_CODES);

export const isVehicleAvailabilityBookingIssueAvailable = (
  availableBookingIssues: string[],
  issues: EditorIssue[]
): boolean => {
  return (
    availableBookingIssues.some((issue: string) =>
      Object.values(VehicleAvailabilityBookingIssues).find((vehicleIssue) => vehicleIssue === issue)
    ) &&
    issues?.some((issue: EditorIssue) =>
      Object.values(VehicleAvailabilityBookingIssues).find((vehicleIssue) => vehicleIssue === issue.code)
    )
  );
};

export const isVehicleYoungDriverIssueAvailable = (
  availableBookingIssues: string[],
  issues: EditorIssue[]
): boolean => {
  return (
    !!availableBookingIssues.find((issue: string) => issue === VehicleNotValidForYoungDriverBookingIssue) &&
    !!issues.find((issue: EditorIssue) => issue.code === VehicleNotValidForYoungDriverBookingIssue)
  );
};

export const isDriverBookingIssueAvailable = (availableBookingIssues: string[], issues: EditorIssue[]): boolean => {
  return (
    !!availableBookingIssues.find((issue: string) => issue === DriverNotValidForBrandBookingIssue) &&
    !!issues.find((issue: EditorIssue) => issue.code === DriverNotValidForBrandBookingIssue)
  );
};

export const isBillToBookingIssueAvailable = (availableBookingIssues: string[], issues: EditorIssue[]): boolean => {
  return (
    availableBookingIssues.some((issue: string) =>
      Object.values(BillToBookingIssues).find((billToIssue) => billToIssue === issue)
    ) &&
    issues?.some((issue: EditorIssue) =>
      Object.values(BillToBookingIssues).find((billToIssue) => billToIssue === issue.code)
    )
  );
};

export const isPeoBookingIssueAvailable = (availableBookingIssues: string[], issues: EditorIssue[]): boolean => {
  return (
    availableBookingIssues.some((issue: string) =>
      Object.values(PeoBookingIssues).find((peoIssue) => peoIssue === issue)
    ) &&
    issues?.some((issue: EditorIssue) => Object.values(PeoBookingIssues).find((peoIssue) => peoIssue === issue.code))
  );
};

export const getPersistedEquipment = (issues: EditorIssue[], equipment: Equipment[]): string[] => {
  const persistedEquipment: string[] = [];

  equipment.forEach((item: Equipment, index: number) => {
    const equipmentIsUnavailable = !!issues.find((issue) => {
      if (issue.code === PeoBookingIssues.EquipmentUnavailable && issue.paths) {
        const path = issue.paths[0];
        const pathIndex = Number(path.charAt(path.length - 1));
        return pathIndex === index;
      }
      return false;
    });

    if (!!item.type && !equipmentIsUnavailable) {
      persistedEquipment.push(item.type);
    }
  });

  return uniq(persistedEquipment);
};

export const getPersistedProtections = (issues: EditorIssue[], protections: Protection[]): string[] => {
  const filteredIssues = issues.filter((issue) => issue?.code === PeoBookingIssues.ProtectionUnavailable);

  const filteredProtections = protections.filter((protection) => {
    return !filteredIssues.some((issue) => {
      const issueMessage = issue.localizedMessage?.split(':')[1]?.trim();
      return issueMessage === parseUrn(protection.type);
    });
  });
  return uniq(filteredProtections.map((protection) => protection.type));
};

export const getEhiMessagesAsSnackbarMessages = (
  ehiMessages: ResponseMessage[],
  excludedBookingIssues: string[]
): DescriptionWithAction[] => {
  const filteredMessages = ehiMessages.filter((message) => !excludedBookingIssues.includes(message.code));
  return filteredMessages.map((message): DescriptionWithAction | undefined => {
    if (message.supportInformation) {
      return { message: message.supportInformation };
    } else {
      return { message: message.localizedMessage ?? EMPTY_VALUE };
    }
  }) as DescriptionWithAction[];
};
